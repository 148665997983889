<template lang="pug">
header(:class="{'home-page': isHomePage}")
  .top
    .container
      .row
        .col-md-12.col-custom-padding
          nav.navbar
            router-link.navbar-brand.logo(:to="{name: 'home', params: { collectionType: $route.params.collectionType }}" v-if="logoFont")
              img(:src="logoFont.logo" :alt="resellerInfo.name")
            div.nav-title(:class="{ withLogo: (logoFont ? logoFont.logo : '') }") {{ headerTitle }}

            .abstract-forms(v-if="getAbstractFormsCollection.length > 1")
              label(
                class="abstract-forms-item"
                v-for="(item, key) in getAbstractFormsCollection"
                :key="key"
              )
                input(
                  type="radio"
                  name="abstract-form"
                  class="abstract-forms-input"
                  :value="`${item.toLowerCase()}`"
                  :checked="item.toLowerCase() === generalFormsCollectionName"
                  @change="changeCollection(item)"
                )
                span {{ $t(item.toLowerCase()) }}

  .bottom(:class="{'bottom-campaign': Object.keys(campaigns).length }")
    .banner-content-wrapper(v-if="!Object.keys(campaigns).length && showBannerContent")
      .banner-content
        h1.banner-heading {{ headerHeadlines.headline }}
        h5.banner-subheading {{ headerHeadlines.intro }}

    .main-campaign-wrapper(v-cloak v-if="Object.keys(campaigns).length")
      .main-campaign-headline {{ campaigns.headline }}
      .main-campaign
        .main-campaign-info
          .main-campaign-title
            .main-campaign-title-name {{campaigns.intro}}
          .main-campaign-description(v-if="hasDeliveryTime")
            .main-campaign-title-delivery-days(v-if="mainCampaign.deliveryTime")
              span {{mainCampaign.grade}} {{mainCampaign.name}} &nbsp;
              strong {{ deliveryTimeText }}: {{ getDays(mainCampaign.deliveryTime.from, false) }} - {{ getDays(mainCampaign.deliveryTime.to, 'short') }}
          .main-campaign-price
            .main-campaign-price-text
              span.num {{ standardPrice }}
              span.mes {{ currencySymbol }}/{{ $t('mon') }}
            a.btn.btn-og.invert.btn-hover.slide-item-price-btn(
              :href="campaigns.link"
            ) {{ $t('order') }}
</template>

<script>
  import { mapState, mapActions, mapGetters, } from 'vuex';
  import { getDaysOrWeeks, getCollectionName, } from './../../helpers/helpers.js';

  export default {
    name: 'VHeader',
    data: () => ({
      mainCampaign: {},
      isConfirmed: false
    }),
    computed: {
      ...mapState('global', ['isHomePage', ]),
      ...mapState('reseller', {
        resellerInfo: state => state.resellerInfo,
        logoFont: state => state.customStyle.logoFont || '',
        headerTitle: state => state.customStyle.topHeaders && !!state.customStyle.topHeaders.data.length ? state.customStyle.topHeaders.data[0].headline : '',
        campaigns: state => state.customStyle.campaigns && !!state.customStyle.campaigns.data.length ? state.customStyle.campaigns.data[0] : [],
        hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
        headerHeadlines: (state) =>
        state.customStyle.headerHeadlines &&
        !!state.customStyle.headerHeadlines.data.length
          ? state.customStyle.headerHeadlines.data[0]
          : [],
        deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
        currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
        market: state => state.resellerInfo?.market || {},
      }),
      ...mapGetters('reseller', ['getPartnerKey', ]),
      ...mapGetters('filter', ['getAbstractFormsCollection', ]),
      generalFormsCollectionName: {
        get(){
          return this.$store.state.global.generalFormsCollectionName
        },
        set(value){
          this.$store.commit('global/setGeneralFormsCollectionName', value)
        }
      },
      standardPrice() {
        if (this.campaigns.price) {
          return this.campaigns.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }

        return ''
      },
      showBannerContent() {
        return this.headerHeadlines.headline || this.headerHeadlines.intro;
      },
    },
    methods: {
      ...mapActions('global', ['setFinanceForm', ]),
      ...mapActions('reseller', ['updateBuilder', ]),
      ...mapActions('filter', ['getDefaultFinanceFormId', ]),
      getCollectionName,
      getDays(days, flag) {
        const daysParam = getDaysOrWeeks(days, flag, this.market.lang);

        return `${daysParam.number} ${daysParam.text}`;
      },
      async changeCollection(item) {
        const itemLower = item.toLowerCase();
        const activeCollection = await this.getCollectionName(itemLower, 'url');

        this.generalFormsCollectionName = itemLower;

        this.$router.push({
          name: 'home',
          params: {
            collectionType: activeCollection,
          }
        });

        window.location.reload();

      }
    },
    watch: {
      campaigns(data) {
        const { link, } = data;

        if (!link) return false;

        const linkIdString = link.split('?id=')[1];
        const linkId = Number.parseInt(linkIdString);

        if (!linkIdString) return false;

        // eslint-disable-next-line
        axios.get(`vehicles/${linkId}`, {
          headers: {
            'Authorization': this.getPartnerKey,
          }
        })
          .then(response => {
            this.$data.mainCampaign = JSON.parse(JSON.stringify(response.data));
          });
      },
      '$route.params': function() {
        if(window.location.pathname.includes('confirmed')) {
          this.$set(this.$data, 'isConfirmed', true)
        } else {
          this.$set(this.$data, 'isConfirmed', false)
        }
      }
    },
  }
</script>

<style lang="sass">
  @import '@/style/components/Header.sass'
</style>
