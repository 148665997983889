import {PMT} from "@/utils/calc";
import {isFormsWithResidual} from "@/helpers/helpers";

export default {
    currentPricesObject: (state, getters, rootState, rootGetters) => rootGetters['product/getCurrentPricesObject'],
    currentCostsObject: (state, getters, rootState, rootGetters) => rootGetters['product/getCurrentCostsObject'],
    vehicleRetailPrice: (state, getters) => getters.currentPricesObject?.rsp || 0,
    vehicleNetPrice: (state, getters) => getters.currentPricesObject?.net || 0,
    cashPaymentPercentage: (state, getters, rootState) => {
        if (rootState.reseller.resellerInfo.settings?.useCashPayments === false && rootState.product.priceForm === 'Private leasing') return 0;

        const storeCashPayment = rootState.order.cashPayment;
        if (storeCashPayment || storeCashPayment === 0) return storeCashPayment

        const defaultCashPayment = getters.currentPricesObject?.default_cash_payment;
        return getters.currentPricesObject?.useCashValues && getters.currentPricesObject.cashValues[defaultCashPayment]
            || defaultCashPayment
            || 0;
    },
    cashPayment: (state, getters, rootState) => {
        if (rootState.reseller.resellerInfo.settings?.useDownPayments === true && getters.currentCostsObject?.based_on === 'Fast pris') {
            const fixedDownpayments = getters.currentCostsObject?.downPayments?.data;
            const selectedDownpayment = rootState.order.vehicleCostDownPaymentId;
            if (fixedDownpayments && selectedDownpayment) {
                const fixedDownpayment = fixedDownpayments.find(({id}) => id === selectedDownpayment);
                if (fixedDownpayment) return fixedDownpayment.value;
            }

            return getters.currentCostsObject.down_payment || 0;
        }

        return getters.cashPaymentPercentage * getters.netPriceInclAccessoriesAndVehicleColor / 100
    },
    residualValuePercentage: (state, getters, rootState, rootGetters) => {
        const vehicleResidual = getters.currentCostsObject?.residual || null;
        if (!isFormsWithResidual(rootState.product.priceForm)) return vehicleResidual;

        const storeResidual = rootState.order.residual;
        if (storeResidual || storeResidual === 0) return storeResidual

        const defaultsResidual = rootGetters['product/getDefaults'][rootState.product.priceForm]?.residual;
        return defaultsResidual || vehicleResidual || 0;
    },
    residualValue: (state, getters) => getters.residualValuePercentage * getters.vehicleRetailPrice / 100,
    leasingPeriodMonths: (state, getters) => getters.currentCostsObject?.months,
    interestRate: (state, getters) => getters.currentPricesObject?.rate / 100 / 12,
    paymentDueParameter: (state, getters, rootState) => rootState.product.priceForm === 'Down payment' ? 0 : 1,

    calculateMonthlyCost: (state, getters) => (amount, residual) => Math.round(PMT(getters.interestRate, getters.leasingPeriodMonths, -amount, residual, getters.paymentDueParameter)),

    monthlyCostOfVehicle: (state, getters, rootState) => {
        if (getters.currentCostsObject?.based_on === 'Restvärde') {
            return getters.calculateMonthlyCost(getters.vehicleNetPrice * (1 - getters.cashPaymentPercentage / 100), getters.residualValue);
        }
        if (getters.currentCostsObject?.based_on === 'Fast pris') {
            const fixedDownpayments = getters.currentCostsObject?.downPayments?.data;
            const selectedDownpayment = rootState.order.vehicleCostDownPaymentId;
            if (fixedDownpayments && selectedDownpayment) {
                const fixedDownpayment = fixedDownpayments.find(({id}) => id === selectedDownpayment);
                if (fixedDownpayment) return fixedDownpayment.monthly_cost;
            }
            
            return getters.currentCostsObject.monthly_cost;
        }
        return 0;
    },

    serviceCost: (state, getters) => getters.currentCostsObject?.service_cost || 0,
    costOfHomeDelivery: (state, getters, rootState) => rootState.product.deliveryType === 'delivery_type_home' && getters.currentPricesObject?.home_delivery || 0,
    vehicleColor: (state, getters, rootState) => getters.currentCostsObject?.vehicleColors?.data.find(({id}) => id === rootState.order.vehicleColorId),

    monthlyCostOfVehicleColor: (state, getters, rootState) => {
        const vehicleColorPrice = getters.vehicleColor?.price;
        if (!vehicleColorPrice) return 0;

        const {total_price: totalPrice, residual, based_on, monthly_price} = vehicleColorPrice;
        if (based_on === 'Fast pris') return monthly_price;

        return rootState.product.priceForm === 'Down payment'
            ? getters.calculateMonthlyCost(totalPrice * (1 - getters.cashPaymentPercentage / 100), 0)
            : getters.calculateMonthlyCost(totalPrice, totalPrice * residual / 100);
    },
    monthlyCostOfAccessories: (state, getters, rootState) =>
        rootState.product.selectedAccessories.reduce((sum, accessoryId) => {
            const accessory = rootState.product.vehicle.accessories.data.find(accessor => accessor.id === accessoryId);
            if (!accessory) return sum;

            if (accessory.based_on === 'Totalbelopp') {
                const accessoryMonthlyCost = rootState.product.priceForm === 'Down payment'
                    ? getters.calculateMonthlyCost(accessory.total_price * (1 - getters.cashPaymentPercentage / 100), 0)
                    : getters.calculateMonthlyCost(accessory.total_price, accessory.total_price * accessory.residual / 100);
                return sum + Math.round(accessoryMonthlyCost)
            }

            if (accessory.based_on === 'Månadspris' || accessory.based_on === 'Månedlig pris') {
                return sum + accessory.monthly_price
            }

            return sum;
        }, 0),

    vehicleExtraCosts: (state, getters) => {
        const extraFields = getters.currentCostsObject?.extraFields?.data
        if (!extraFields) return 0
        return extraFields.reduce((acc, { price }) => acc + price, 0)
    },

    monthlyCostOfAccessoriesAndEquipment: (state, getters) => getters.monthlyCostOfAccessories + getters.monthlyCostOfVehicleColor + getters.serviceCost + getters.costOfHomeDelivery,
    totalMonthlyCost: (state, getters) => getters.monthlyCostOfVehicle + getters.monthlyCostOfAccessoriesAndEquipment + getters.vehicleExtraCosts,

    totalCostOfAccessories: (state, getters, rootState) =>
        rootState.product.selectedAccessories.reduce((sum, accessoryId) => {
            const accessory = rootState.product.vehicle.accessories.data.find(accessor => accessor.id === accessoryId);
            return accessory && accessory.based_on === 'Totalbelopp'
                ? sum + accessory.total_price
                : sum;
        }, 0),
    totalCostOfVehicleColor: (state, getters) => getters.vehicleColor?.price?.total_price || 0,
    netPriceInclAccessories: (state, getters) => getters.vehicleNetPrice + getters.totalCostOfAccessories,
    netPriceInclAccessoriesAndVehicleColor: (state, getters) => getters.netPriceInclAccessories + getters.totalCostOfVehicleColor,

    totalCreditAmount: (state, getters) => getters.netPriceInclAccessoriesAndVehicleColor - getters.cashPayment,
    totalLeasePayment: (state, getters) => getters.totalMonthlyCost * getters.leasingPeriodMonths + getters.cashPayment + getters.costOfHomeDelivery,
    twelveMonthsLeasePayment: (state, getters) => getters.totalMonthlyCost * 12 + getters.cashPayment,

    retailSalesPriceRSPInclAccessories: (state, getters) => getters.vehicleRetailPrice + getters.totalCostOfAccessories + getters.totalCostOfVehicleColor,

    residualValueOfAccessories: (state, getters, rootState) =>
        rootState.product.selectedAccessories.reduce((sum, accessoryId) => {
                const accessory = rootState.product.vehicle.accessories.data.find(accessor => accessor.id === accessoryId);
                return accessory && accessory.based_on === 'Totalbelopp'
                    ? sum + accessory.total_price * accessory.residual / 100
                    : sum;
            }, 0),

    residualValueOfVehicleColor: (state, getters) => getters.vehicleColor?.price?.total_price * getters.vehicleColor?.price?.residual / 100 || 0,
    residualValueInclAccessories: (state, getters, rootState) => {
        if (rootState.product.priceForm === 'Down payment') return getters.residualValue;
        return getters.residualValue + getters.residualValueOfAccessories + getters.residualValueOfVehicleColor;
    },
    getSelectedAccessoriesString: (state, getters, rootState) => {
        const mapFunctionLeasing = item => `${item.name} Totalpris SEK: ${item.total_price} / Restvärde %: ${item.residual} / Restvärde SEK: ${(item.total_price / 100 * item.residual).toFixed(2)} / Månadskostnad SEK: ${item.calculated_price}`
        const mapFunctionLoan = item => `${item.name} ${item.total_price} SEK`
        const mapFunction = rootState.product.priceForm === 'Down payment' ? mapFunctionLoan : mapFunctionLeasing;
        return rootState.product.vehicle.accessories.data.filter(item => rootState.product.selectedAccessories.includes(item.id)).map(mapFunction).join('\n');
    },
    equipmentSpecsString: (state, getters, rootState) => rootState.product.vehicle.specs_list.join(' / '),
}